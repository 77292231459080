/* You can add global styles to this file, and also import other style files */
.dx-menu-horizontal {
  height: 57px;
    text-align: right;
    background: #03a9f4;
}
.dx-menu-item {
  color: rgb(54 54 64);
}
.top-menu{
  z-index: 100;
  overflow: hidden;
    position: fixed;
    right: 0;
    width: 100%;
}
.content-block{
  margin-top: 70px;
}
.screen-large .responsive-paddings {
  padding: 13px;
}
.dx-drawer-shrink .dx-drawer-panel-content {
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  height: 100%;
  z-index: 1000 !important;
}
.dx-datagrid-content{
  cursor: pointer;
}
// .dx-menu{
//     top: 58px;
//     position: fixed;
//     background: #f2f2f2;
//     width: 100%;
//     padding-right: 250px;
// }
// .content-block {
//     margin-left: 40px;
//     margin-right: 40px;
//     margin-top: 40px;
// }

.loading_screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:#fff;
    z-index: 9999;
  }
  .loading_anim img {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
    width: 100px;
  }
  .help{
      color: rgba(117,117,117,.87);
      font-size: 12px;
  }

  .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected > .dx-treeview-item * {
    color: black !important;
}

.dx-swatch-additional .dx-scrollable-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // background: #afe6ff;
}

.dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
  background: #cfecfa !important;
}
.dx-menu-item {
  color: #ffffff;
}

.user-info[_ngcontent-lti-c253] .image-container[_ngcontent-lti-c253] {
  overflow: hidden;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: none !important;
  margin: 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
}
.dx-toolbar {
  width: 100%;
  color: rgb(255 255 255 / 87%) !important;
}
.dx-toolbar-label > div {
  color: white !important;
}
#navigation-header[_ngcontent-ovf-c267] .menu-button .dx-icon {
  color: rgba(255, 255, 255, 0.87) !important;
}